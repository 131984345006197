import React from "react";
import { Grid, Typography } from "@mui/material";
import {
  MarketCalibration,
  NewBusiness,
  Renewals,
  BOBmanagement,
} from "../../../assets/custom_icons/home_card_images";
import "@fontsource/roboto/400.css";
import { colors } from "../../../utils/colors";

const RectangleCards = () => {
  let cards = [
    {
      title: "Market Calibration",
      icon: <MarketCalibration />,
      body: "See how you stack up against the open market and best calibrate your rates per market to win more business",
    },
    {
      title: "New Business",
      icon: <NewBusiness />,
      body: "Streamline ACA data collection & strategically price new business opportunities",
    },
    {
      title: "Renewals",
      icon: <Renewals />,
      body: "Drive renewal strategy per client with open market quotes. Protect groups on master and transition others to ACA",
    },
    {
      title: "B.O.B. Management",
      icon: <BOBmanagement />,
      body: "Protect your book of business against the open market with real-time competitive insights",
    },
  ];
  return (
    <Grid
      container
      className="card-gradient overflow-hidden p-b-l"
      id="rectangle-cards"
      style={{ height: "440px" }}
    >
      <Grid
        item
        className="main-box-shadow m-b-xl main-border-radius"
        sx={{ width: "80%", height: "100%", margin: "auto" }}
      >
        <Grid
          container
          className="primary-white-text d-flex primary-white-bc main-border-radius"
        >
          {cards.map((card, i) => (
            <Grid
              item
              sx={12}
              md={6}
              lg={3}
              key={i}
              className="rectangle-cards"
            >
              <div
                style={{
                  border: "none",
                  boxShadow: "none",
                  borderRadius: "none",
                }}
                className="main-border-radius"
              >
                <div style={{ textAlign: "center" }} className="m-auto">
                  <div
                    className="p-b-xl font-barlow p-t-xl"
                    style={{
                      fontSize: "32px",
                      fontWeight: "600",
                      color: colors.primaryBlack,
                    }}
                  >
                    {card.title}
                  </div>
                  <div className="p-b-m">{card.icon}</div>

                  <Typography
                    sx={{
                      fontSize: 14,
                      width: "50%",
                      margin: "auto",
                    }}
                    color="text.secondary"
                    gutterBottom
                  >
                    {card.body}
                  </Typography>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RectangleCards;
