import React, { useState, useCallback, useRef } from "react";
import {
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextareaAutosize
} from "@mui/material";
import BlackSlainTechLogo from "../../assets/custom_icons/slain_tech_logo_black";
import DemoLogo from "../../assets/custom_icons/demo_logo";
import { colors } from "../../utils/colors";
import { Link } from "react-router-dom";
import { states } from "../demo/demo_utils/states";
import emailjs from "@emailjs/browser";

const ContactUs = () => {
  const emailRef = useRef();
  const [contactData, setContactData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    company: "",
    phone: "",
    state: "",
    message: "",
  });

  const handleDataChange = useCallback(
    (e) => {
      let { value } = e.target;

      setContactData({ ...contactData, [e.target.name]: value });
    },
    [contactData]
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      console.log(contactData);

      emailjs
        .sendForm(
          "service_1t3x7ee",
          "template_7jwfor6",
          emailRef.current,
          "user_VfoIvnHsugNcuGtAOmHON"
        )
        .then((result) => {
          console.log(result);
        });
    },
    [contactData]
  );

  return (
    <div>
      <Box
        className="primary-white-text p-l main-box-shadow font-l"
        sx={{ height: "15%", padding: "20px" }}
      >
        {" "}
        <Link to={"/"}>
          <BlackSlainTechLogo />
        </Link>
      </Box>

      <div className="d-flex w-80 m-auto m-t-80 demo-form-container">
        <div style={{ width: "60%" }}>
          <Typography
            variant="h2"
            className="font-title m-t-xl font-barlow demo-title"
            style={{ marginBottom: "20px", paddingRight: "10%" }}
          >
            We would love to get in touch with you
          </Typography>
          <Typography
            variant="p"
            className="font-barlow font-xxl demo-body-text"
            style={{
              width: "70%",
              display: "block",
              lineHeight: "32px",
              marginBottom: "30px",
            }}
          >
            Let us know what questions you have and we will get in touch with
            you at our earliest convenience.
          </Typography>
          <Typography
            variant="p"
            className="font-barlow font-xxl demo-body-text"
            style={{
              width: "60%",
              display: "block",
              lineHeight: "32px",
              marginBottom: "70px",
            }}
          >
            Cheers!
            <br /> The SlainTech Team
          </Typography>
          <div className="desktop-responsive">
            <DemoLogo />
          </div>
        </div>

        <div
          className="demo-form"
          style={{ width: "40%", marginBottom: "100px" }}
        >
          <div className="main-box-shadow p-l" style={{ borderRadius: "20px" }}>
            <Typography
              style={{
                fontSize: "22px",
                marginBottom: "22px",
                fontStyle: "normal",
                fontWeight: "400 !important",
              }}
            >
              Contact us
            </Typography>

            <form ref={emailRef} onSubmit={handleSubmit}>
              <TextField
                style={{ width: "100%", marginBottom: "20px" }}
                id="outlined-required"
                label="First name"
                value={contactData.first_name}
                name="first_name"
                onFocus={(e) => (e.target.value = "")}
                onChange={handleDataChange}
              />
              <TextField
                style={{ width: "100%", marginBottom: "20px" }}
                id="outlined-required"
                label="Last name"
                value={contactData.last_name}
                name="last_name"
                onFocus={(e) => (e.target.value = "")}
                onChange={handleDataChange}
              />
              <TextField
                style={{ width: "100%", marginBottom: "20px" }}
                id="outlined-required"
                label="Email"
                value={contactData.email}
                name="email"
                onFocus={(e) => (e.target.value = "")}
                onChange={handleDataChange}
              />
              <TextField
                style={{ width: "100%", marginBottom: "20px" }}
                id="outlined-required"
                label="Company"
                value={contactData.company}
                name="company"
                onFocus={(e) => (e.target.value = "")}
                onChange={handleDataChange}
              />
              <TextField
                style={{ width: "100%", marginBottom: "20px" }}
                id="outlined-required"
                label="Phone (optional)"
                value={contactData.phone}
                name="phone"
                onFocus={(e) => (e.target.value = "")}
                onChange={handleDataChange}
              />
              <br />
              <FormControl style={{ width: "55%" }}>
                <InputLabel id="state">State</InputLabel>
                <Select
                  style={{ marginBottom: "30px" }}
                  labelId="state"
                  id="state"
                  label="State"
                  placeholder="Select"
                  value={contactData.state}
                  name="state"
                  onChange={handleDataChange}
                >
                  {states.map((state, i) => (
                    <MenuItem value={state}>{state}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <br />
              <TextareaAutosize
                name="message"
                label="How can we help?"
                onFocus={(e) => (e.target.value = "")}
                onChange={handleDataChange}
                rows={2}
                maxRows={4}
                style={{ width: "100%", height: 150 }}
                value={contactData.message}
                id="text-field"
                type="text"
              />

              <Box marginTop={"25px"}>
                <Button
                  style={{
                    backgroundColor: colors.primaryGreen,
                    borderRadius: "20px",
                    color: "black",
                    padding: "10px 30px"
                  }}
                  variant="contained"
                  type="submit"
                >
                  <Typography transform="none" variant="subtitle2">
                    Send demo request
                  </Typography>
                </Button>
              </Box>
            </form>
          </div>
        </div>

        <div className="mobile-responsive demo-logo">
          <DemoLogo />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
