import React from "react";

export default function SlainTechLogo() {
  return (
    <svg
      width="158"
      height="30"
      viewBox="0 0 158 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3622 29.304C8.38619 29.304 6.66353 28.9873 5.19419 28.354C3.72486 27.7207 2.58486 26.834 1.77419 25.694C0.988858 24.554 0.596191 23.2113 0.596191 21.666V20.716C0.596191 20.5893 0.634191 20.488 0.710191 20.412C0.811525 20.3107 0.925525 20.26 1.05219 20.26H4.43419C4.56086 20.26 4.66219 20.3107 4.73819 20.412C4.83952 20.488 4.89019 20.5893 4.89019 20.716V21.438C4.89019 22.6033 5.40952 23.5787 6.44819 24.364C7.48686 25.124 8.90553 25.504 10.7042 25.504C12.2495 25.504 13.4149 25.1747 14.2002 24.516C14.9855 23.8573 15.3782 23.0087 15.3782 21.97C15.3782 21.2607 15.1629 20.6653 14.7322 20.184C14.3015 19.6773 13.6809 19.2213 12.8702 18.816C12.0849 18.4107 10.8942 17.904 9.29819 17.296C7.49953 16.6373 6.04286 16.0167 4.92819 15.434C3.81352 14.8513 2.87619 14.066 2.11619 13.078C1.38152 12.0647 1.01419 10.8107 1.01419 9.31601C1.01419 7.08668 1.82486 5.32601 3.44619 4.03401C5.06752 2.74201 7.23353 2.09601 9.94419 2.09601C11.8442 2.09601 13.5162 2.42534 14.9602 3.08401C16.4295 3.74267 17.5695 4.66734 18.3802 5.85801C19.1909 7.02334 19.5962 8.37867 19.5962 9.92401V10.57C19.5962 10.6967 19.5455 10.8107 19.4442 10.912C19.3682 10.988 19.2669 11.026 19.1402 11.026H15.7202C15.5935 11.026 15.4795 10.988 15.3782 10.912C15.3022 10.8107 15.2642 10.6967 15.2642 10.57V10.152C15.2642 8.96134 14.7702 7.96067 13.7822 7.15001C12.8195 6.31401 11.4769 5.89601 9.75419 5.89601C8.36086 5.89601 7.27153 6.18734 6.48619 6.77001C5.72619 7.35267 5.34619 8.17601 5.34619 9.24001C5.34619 10 5.54886 10.6207 5.95419 11.102C6.35953 11.5833 6.96752 12.0267 7.77819 12.432C8.58886 12.812 9.84286 13.306 11.5402 13.914C13.3389 14.598 14.7702 15.2187 15.8342 15.776C16.9235 16.3333 17.8482 17.1187 18.6082 18.132C19.3935 19.12 19.7862 20.3613 19.7862 21.856C19.7862 24.136 18.9375 25.9473 17.2402 27.29C15.5682 28.6327 13.2755 29.304 10.3622 29.304Z"
        fill="white"
      />
      <path
        d="M24.2634 29C24.1367 29 24.0227 28.962 23.9214 28.886C23.8454 28.7847 23.8074 28.6707 23.8074 28.544V2.85601C23.8074 2.72934 23.8454 2.62801 23.9214 2.55201C24.0227 2.45067 24.1367 2.40001 24.2634 2.40001H27.7594C27.886 2.40001 27.9874 2.45067 28.0634 2.55201C28.1647 2.62801 28.2154 2.72934 28.2154 2.85601V28.544C28.2154 28.6707 28.1647 28.7847 28.0634 28.886C27.9874 28.962 27.886 29 27.7594 29H24.2634Z"
        fill="white"
      />
      <path
        d="M40.3092 9.27801C41.9052 9.27801 43.3112 9.55667 44.5272 10.114C45.7432 10.6713 46.6805 11.444 47.3392 12.432C47.9978 13.3947 48.3272 14.4967 48.3272 15.738V28.544C48.3272 28.6707 48.2765 28.7847 48.1752 28.886C48.0992 28.962 47.9978 29 47.8712 29H44.3752C44.2485 29 44.1345 28.962 44.0332 28.886C43.9572 28.7847 43.9192 28.6707 43.9192 28.544V27.328C43.9192 27.252 43.8938 27.214 43.8432 27.214C43.7925 27.1887 43.7418 27.2013 43.6912 27.252C42.4498 28.62 40.6512 29.304 38.2952 29.304C36.3952 29.304 34.8245 28.8227 33.5832 27.86C32.3672 26.8973 31.7592 25.466 31.7592 23.566C31.7592 21.59 32.4432 20.0573 33.8112 18.968C35.2045 17.8533 37.1552 17.296 39.6632 17.296H43.7292C43.8558 17.296 43.9192 17.2327 43.9192 17.106V16.194C43.9192 15.206 43.6278 14.446 43.0452 13.914C42.4625 13.3567 41.5885 13.078 40.4232 13.078C39.4605 13.078 38.6752 13.2553 38.0672 13.61C37.4845 13.9393 37.1172 14.408 36.9652 15.016C36.8892 15.32 36.7118 15.4467 36.4332 15.396L32.7472 14.94C32.6205 14.9147 32.5065 14.8767 32.4052 14.826C32.3292 14.75 32.3038 14.6613 32.3292 14.56C32.5318 13.0147 33.3552 11.748 34.7992 10.76C36.2685 9.77201 38.1052 9.27801 40.3092 9.27801ZM39.3972 25.808C40.6385 25.808 41.7025 25.4787 42.5892 24.82C43.4758 24.1613 43.9192 23.3253 43.9192 22.312V20.526C43.9192 20.3993 43.8558 20.336 43.7292 20.336H40.5372C39.1945 20.336 38.1305 20.5893 37.3452 21.096C36.5852 21.6027 36.2052 22.3247 36.2052 23.262C36.2052 24.0727 36.4965 24.706 37.0792 25.162C37.6872 25.5927 38.4598 25.808 39.3972 25.808Z"
        fill="white"
      />
      <path
        d="M53.4367 29C53.31 29 53.196 28.962 53.0947 28.886C53.0187 28.7847 52.9807 28.6707 52.9807 28.544V10C52.9807 9.87334 53.0187 9.77201 53.0947 9.69601C53.196 9.59467 53.31 9.54401 53.4367 9.54401H56.9327C57.0593 9.54401 57.1607 9.59467 57.2367 9.69601C57.338 9.77201 57.3887 9.87334 57.3887 10V28.544C57.3887 28.6707 57.338 28.7847 57.2367 28.886C57.1607 28.962 57.0593 29 56.9327 29H53.4367Z"
        fill="white"
      />
      <path
        d="M71.7482 9.27801C73.8002 9.27801 75.4342 9.88601 76.6502 11.102C77.8916 12.318 78.5122 13.9773 78.5122 16.08V28.544C78.5122 28.6707 78.4616 28.7847 78.3602 28.886C78.2842 28.962 78.1829 29 78.0562 29H74.5602C74.4336 29 74.3196 28.962 74.2182 28.886C74.1422 28.7847 74.1042 28.6707 74.1042 28.544V16.992C74.1042 15.8267 73.7749 14.8893 73.1162 14.18C72.4576 13.4453 71.5836 13.078 70.4942 13.078C69.4049 13.078 68.5182 13.4327 67.8342 14.142C67.1756 14.8513 66.8462 15.7887 66.8462 16.954V28.544C66.8462 28.6707 66.7956 28.7847 66.6942 28.886C66.6182 28.962 66.5169 29 66.3902 29H62.8942C62.7676 29 62.6536 28.962 62.5522 28.886C62.4762 28.7847 62.4382 28.6707 62.4382 28.544V10.038C62.4382 9.91134 62.4762 9.81001 62.5522 9.73401C62.6536 9.63267 62.7676 9.58201 62.8942 9.58201H66.3902C66.5169 9.58201 66.6182 9.63267 66.6942 9.73401C66.7956 9.81001 66.8462 9.91134 66.8462 10.038V11.254C66.8462 11.33 66.8716 11.3807 66.9222 11.406C66.9729 11.4313 67.0109 11.406 67.0362 11.33C68.0749 9.96201 69.6456 9.27801 71.7482 9.27801Z"
        fill="white"
      />
      <path
        d="M99.1061 2.40001C99.2328 2.40001 99.3341 2.45067 99.4101 2.55201C99.5115 2.62801 99.5621 2.72934 99.5621 2.85601V5.78201C99.5621 5.90867 99.5115 6.02268 99.4101 6.12401C99.3341 6.20001 99.2328 6.23801 99.1061 6.23801H92.1141C91.9875 6.23801 91.9241 6.30134 91.9241 6.42801V28.544C91.9241 28.6707 91.8735 28.7847 91.7721 28.886C91.6961 28.962 91.5948 29 91.4681 29H87.9721C87.8455 29 87.7315 28.962 87.6301 28.886C87.5541 28.7847 87.5161 28.6707 87.5161 28.544V6.42801C87.5161 6.30134 87.4528 6.23801 87.3261 6.23801H80.6001C80.4735 6.23801 80.3595 6.20001 80.2581 6.12401C80.1821 6.02268 80.1441 5.90867 80.1441 5.78201V2.85601C80.1441 2.72934 80.1821 2.62801 80.2581 2.55201C80.3595 2.45067 80.4735 2.40001 80.6001 2.40001H99.1061Z"
        fill="white"
      />
      <path
        d="M116.586 17.106C116.687 17.7393 116.738 18.4487 116.738 19.234L116.7 20.412C116.7 20.716 116.548 20.868 116.244 20.868H104.35C104.223 20.868 104.16 20.9313 104.16 21.058C104.236 21.894 104.35 22.464 104.502 22.768C105.11 24.592 106.592 25.5167 108.948 25.542C110.671 25.542 112.039 24.8327 113.052 23.414C113.153 23.262 113.28 23.186 113.432 23.186C113.533 23.186 113.622 23.224 113.698 23.3L116.054 25.238C116.282 25.4153 116.32 25.618 116.168 25.846C115.383 26.9607 114.331 27.822 113.014 28.43C111.722 29.038 110.278 29.342 108.682 29.342C106.706 29.342 105.021 28.8987 103.628 28.012C102.26 27.1253 101.259 25.884 100.626 24.288C100.043 22.92 99.7521 21.02 99.7521 18.588C99.7521 17.1187 99.9041 15.928 100.208 15.016C100.715 13.2427 101.665 11.8493 103.058 10.836C104.477 9.79734 106.174 9.27801 108.15 9.27801C113.141 9.27801 115.953 11.8873 116.586 17.106ZM108.15 13.078C107.213 13.078 106.427 13.3187 105.794 13.8C105.186 14.256 104.755 14.902 104.502 15.738C104.35 16.1687 104.249 16.726 104.198 17.41C104.147 17.5367 104.198 17.6 104.35 17.6H112.064C112.191 17.6 112.254 17.5367 112.254 17.41C112.203 16.7767 112.127 16.2953 112.026 15.966C111.798 15.054 111.342 14.3447 110.658 13.838C109.999 13.3313 109.163 13.078 108.15 13.078Z"
        fill="white"
      />
      <path
        d="M128.861 29.304C126.91 29.304 125.225 28.81 123.807 27.822C122.388 26.8087 121.4 25.4533 120.843 23.756C120.437 22.616 120.235 21.096 120.235 19.196C120.235 17.372 120.437 15.8773 120.843 14.712C121.375 13.04 122.35 11.7227 123.769 10.76C125.213 9.77201 126.91 9.27801 128.861 9.27801C130.837 9.27801 132.559 9.77201 134.029 10.76C135.498 11.748 136.473 12.9893 136.955 14.484C137.081 14.8893 137.17 15.2947 137.221 15.7V15.776C137.221 16.0293 137.081 16.1813 136.803 16.232L133.383 16.726H133.307C133.079 16.726 132.927 16.5993 132.851 16.346C132.851 16.2193 132.838 16.118 132.813 16.042C132.762 15.8393 132.711 15.662 132.661 15.51C132.433 14.8007 131.977 14.218 131.293 13.762C130.609 13.306 129.798 13.078 128.861 13.078C127.898 13.078 127.075 13.3313 126.391 13.838C125.732 14.3193 125.289 14.9907 125.061 15.852C124.807 16.6627 124.681 17.79 124.681 19.234C124.681 20.6273 124.795 21.7547 125.023 22.616C125.276 23.5027 125.732 24.212 126.391 24.744C127.049 25.2507 127.873 25.504 128.861 25.504C129.823 25.504 130.647 25.2633 131.331 24.782C132.04 24.2753 132.496 23.604 132.699 22.768C132.724 22.7173 132.737 22.654 132.737 22.578C132.762 22.5527 132.775 22.5147 132.775 22.464C132.851 22.16 133.028 22.0333 133.307 22.084L136.727 22.616C136.853 22.6413 136.955 22.7047 137.031 22.806C137.132 22.882 137.17 22.996 137.145 23.148C137.069 23.5533 137.005 23.8447 136.955 24.022C136.473 25.6433 135.498 26.9353 134.029 27.898C132.559 28.8353 130.837 29.304 128.861 29.304Z"
        fill="white"
      />
      <path
        d="M150.264 9.27801C152.316 9.27801 153.95 9.88601 155.166 11.102C156.408 12.318 157.028 13.9773 157.028 16.08V28.544C157.028 28.6707 156.978 28.7847 156.876 28.886C156.8 28.962 156.699 29 156.572 29H153.076C152.95 29 152.836 28.962 152.734 28.886C152.658 28.7847 152.62 28.6707 152.62 28.544V16.992C152.62 15.8267 152.291 14.8893 151.632 14.18C150.974 13.4453 150.1 13.078 149.01 13.078C147.921 13.078 147.034 13.4327 146.35 14.142C145.692 14.8513 145.362 15.7887 145.362 16.954V28.544C145.362 28.6707 145.312 28.7847 145.21 28.886C145.134 28.962 145.033 29 144.906 29H141.41C141.284 29 141.17 28.962 141.068 28.886C140.992 28.7847 140.954 28.6707 140.954 28.544V2.85601C140.954 2.72934 140.992 2.62801 141.068 2.55201C141.17 2.45067 141.284 2.40001 141.41 2.40001H144.906C145.033 2.40001 145.134 2.45067 145.21 2.55201C145.312 2.62801 145.362 2.72934 145.362 2.85601V11.254C145.362 11.33 145.388 11.3807 145.438 11.406C145.489 11.4313 145.527 11.406 145.552 11.33C146.591 9.96201 148.162 9.27801 150.264 9.27801Z"
        fill="white"
      />
      <circle cx="55" cy="4" r="3" fill="#5AE0A0" />
      <circle cx="41" cy="4" r="3" fill="#5AE0A0" />
    </svg>
  );
}
