import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { colors } from "../../../utils/colors";
import DemoImage from "../../../assets/custom_icons/demo_image";
import {
  DemoImage1,
  DemoImage2,
  DemoImage3,
  DemoImage4,
} from "../../../assets/custom_icons/demo_section_images";

const DemoSection = () => {
  return (
    <Grid className="demo-section-container" style={{ height: "1100px" }}>
      <div className="d-flex position-relative" id="scroll-section">
        <BigDemoImage />
        <ScrollDemoSection />
      </div>
    </Grid>
  );
};

export default DemoSection;

const BigDemoImage = () => {
  return (
    <div className="big-image-container">
      <Box
        className="primary-white-text p-l scroll-section-title"
        style={{ marginLeft: "135px" }}
      >
        <h2 className="text-bolder font-title m-t-xl font-barlow primary-black-text p-b-m">
          In-depth Insights
        </h2>
        <div
          style={{
            fontSize: "24px",
            fontWeight: "600",
            color: colors.primaryBlack,
          }}
          className="font-barlow primary-black-text"
        >
          Simplified open market data for rate calibration, new business,
          renewals, and more.
        </div>
      </Box>
      <DemoImage />
    </div>
  );
};

const ScrollDemoSection = () => {
  return (
    <Grid
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ marginTop: "90px", marginLeft: "auto" }}
      className="scroll-card-container"
    >
      {scrollData.map((data, i) => (
        <Box className="scroll-cards" sx={{ height: "35%" }} key={i}>
          <div className="w-85 m-auto scroll-card">
            <div className="text-center m-auto p-b-m">{data.icon}</div>
            <Typography
              className="scroll-card-text"
              sx={{
                fontSize: 14,
                width: "50%",
                margin: "auto",
              }}
              color="text.secondary"
              gutterBottom
              textAlign="center"
            >
              {data.text}
            </Typography>
          </div>
        </Box>
      ))}
    </Grid>
  );
};

const scrollData = [
  {
    text: "View quarterly open market competitive insights (PEO vs. ACA vs. Level Funded) for target markets and streamline your rate recalibration process",
    icon: <DemoImage1 />,
  },
  {
    text: "Easily benchmark against the open market, automatically match the closest plan(s) and streamline your rate band release to win more business",
    icon: <DemoImage2 />,
  },
  {
    text: "Segment renewals with a strategy recommendation score to streamline your renewal workflow",
    icon: <DemoImage3 />,
  },
  {
    text: "Identify pre-renewal strategy insights to retain and best serve your clients",
    icon: <DemoImage4 />,
  },
];
