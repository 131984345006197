import React from "react";
import { Box } from "@mui/material";
import CheersIcon from "../../../assets/custom_icons/cheers";
import GreenButton from "../../../common_comp/GreenButton";
import { colors } from "../../../utils/colors";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{ width: "100%", paddingTop: "120px" }}
      className="primary-green-bc text-center"
    >
      <CheersIcon />
      <h2
        style={{ fontWeight: "400" }}
        className="text-bolder font-title m-t-xl font-barlow primary-black-text p-b-m"
      >
        We raise a glass to good <br /> health (tech)!
      </h2>
      <GreenButton
        text="Get started"
        backgroundColor={colors.primaryBlack}
        textColor={colors.primaryGreen}
        padding="7px 60px"
        onClick={() => {
          navigate("/request-demo");
        }}
      />
      <div
        className="m-auto font-barlow"
        style={{
          borderTop: "1.5px solid black",
          paddingTop: "25px",
          paddingBottom: "25px",
          marginTop: "120px",
          width: "95%",
        }}
      >
        {" "}
        <p style={{ fontSize: "12px", fontWeight: "500" }}>
          Copyright © SlainTech Inc. 2023. All Rights Reserved.&nbsp; |&nbsp;{" "}
          <a href="google.com" className="primary-black-text">
            Privacy Policy
          </a>{" "}
          &nbsp;|&nbsp;{" "}
          <a className="primary-black-text" href="mailto:gv@slaintech.com">
            Contact Us
          </a>
        </p>
      </div>
    </Box>
  );
};

export default Footer;
