import React from "react";
import { Button } from "@mui/material";

const GreenButton = ({
  text,
  icon,
  onClick,
  iconStyles,
  backgroundColor,
  textColor,
  padding,
  href,
}) => {
  const Icon = icon;
  return (
    <Button
      style={{
        marginTop: "32px",
        borderRadius: "100px",
        backgroundColor: backgroundColor,
        color: textColor,
        fontSize: "14px",
        textTransform: "none",
        padding: padding,
      }}
      variant="contained"
      onClick={() => onClick()}
      href={href}
      className=".primary-green-bc"
    >
      {icon ? <Icon style={iconStyles} /> : null}
      &nbsp; {text}
    </Button>
  );
};

export default GreenButton;
