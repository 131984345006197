import React, { useState, useCallback } from "react";
import axios from "axios";
import {
  Box,
  FormControl,
  InputLabel,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Snackbar,
} from "@mui/material";
import { states } from "./demo_utils/states";
import BlackSlainTechLogo from "../../assets/custom_icons/slain_tech_logo_black";
import DemoLogo from "../../assets/custom_icons/demo_logo";
import GreenButton from "../../common_comp/GreenButton";
import { colors } from "../../utils/colors";
import { Link } from "react-router-dom";

let url =
  "https://docs.google.com/forms/u/0/d/e/1FAIpQLSdlfWsszhpuny9G7smH05GE4JMNWN_tuPX2bL5R4CVbFYTOlw/formResponse";

const RequestDemo = () => {
  const [inputFields, setInputFields] = useState({
    "entry.1884265043": "", // first name
    "entry.458332449": "", // last name
    "entry.459832011": "", // email
    "entry.1623414976": "", // phone number
    "entry.1839313831": "", // company name
    "entry.1064217773": "", // state
    "entry.1228576801": "", // PEO master health
    "entry.1063338339": "", // PEO master States
    "entry.715344054": "", // message
  });
  const [statesSelected, setStatesSelected] = useState([]);

  const [popUp, setPopUp] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal, open } = popUp;

  const handleClick = (newState) => () => {
    setPopUp({ open: true, ...newState });
  };

  const handleClose = () => {
    setPopUp({ ...popUp, open: false });
  };

  const handleTextInputChange = useCallback(
    (input) => (e) => {
      let { value } = e.target;
      console.log(value);
      setInputFields((previousState) => ({
        ...previousState,
        [input]: value,
      }));
    },
    []
  );

  const handleOptionChange = useCallback(
    (input) => (e) => {
      const { value } = e.target;
      setInputFields((previousState) => ({
        ...previousState,
        [input]: value,
      }));
    },
    []
  );
  const handleMultipleChange = useCallback(
    (input) => (e) => {
      const { value } = e.target;
      let stateSentence = "";

      setStatesSelected(value);

      value.forEach((index) => {
        stateSentence += states[index] + " ";
      });

      setInputFields((previousState) => ({
        ...previousState,
        [input]: stateSentence,
      }));
    },
    []
  );

  const handleSubmit = useCallback(() => {
    setInputFields({
      "entry.1884265043": "", // first name
      "entry.458332449": "", // last name
      "entry.459832011": "", // email
      "entry.1623414976": "", // phone number
      "entry.1839313831": "", // company name
      "entry.1064217773": "", // state
      "entry.1228576801": "", // PEO master health
      "entry.1063338339": "", // PEO master States
      "entry.715344054": "", // message
    });
    setStatesSelected([]);
    handleClick();
    let encodedData = new URLSearchParams(
      Object.entries(inputFields)
    ).toString();
    axios
      .post(url, encodedData, {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      })
      .then((response) => {
        console.log(response);
    
      });

      setPopUp({ open: true, vertical: "top", horizontal: "center" });

      setTimeout(function () {
        setPopUp({ ...popUp, open: false });
      }, 3000);
  }, [inputFields, popUp]);

  return (
    <div>
      <Box
        className="primary-white-text p-l main-box-shadow font-l"
        sx={{ height: "15%", padding: "20px" }}
      >
        {" "}
        <Link to={"/"}>
          <BlackSlainTechLogo />
        </Link>
      </Box>




      

      <div className="d-flex w-80 m-auto m-t-80 demo-form-container">
        <div style={{ width: "60%" }}>
          <Typography
            variant="h2"
            className="font-title m-t-xl font-barlow demo-title"
            style={{ marginBottom: "20px", paddingRight: "10%" }}
          >
            We would love to share a demo with you
          </Typography>
          <Typography
            variant="p"
            className="font-barlow font-xxl demo-body-text"
            style={{
              width: "70%",
              display: "block",
              lineHeight: "32px",
              marginBottom: "30px",
            }}
          >
            Schedule your 30-minute demo to see how SlainTech unlocks your Group
            Health Portfolio strategy through insights and analytics.
          </Typography>
          <Typography
            variant="p"
            className="font-barlow font-xxl demo-body-text"
            style={{
              width: "60%",
              display: "block",
              lineHeight: "32px",
              marginBottom: "70px",
            }}
          >
            Cheers!
            <br /> The SlainTech Team
          </Typography>
          <div className="desktop-responsive">
            <DemoLogo />
          </div>
        </div>

        <div
          className="demo-form"
          style={{ width: "40%", marginBottom: "100px" }}
        >
          <div className="main-box-shadow p-l" style={{ borderRadius: "20px" }}>
            <Typography
              style={{
                fontSize: "22px",
                marginBottom: "22px",
                fontStyle: "normal",
                fontWeight: "400 !important",
              }}
            >
              Request your SlainTech demo
            </Typography>

            <form onSubmit={handleSubmit}>
              <TextField
                style={{ width: "100%", marginBottom: "20px" }}
                id="outlined-required"
                label="First name"
                value={inputFields["entry.1884265043"]}
                name="entry.1884265043"
                onFocus={(e) => (e.target.value = "")}
                onChange={handleTextInputChange("entry.1884265043")}
              />
              <TextField
                style={{ width: "100%", marginBottom: "20px" }}
                id="outlined-required"
                label="Last name"
                value={inputFields["entry.458332449"]}
                name="entry.458332449"
                onFocus={(e) => (e.target.value = "")}
                onChange={handleTextInputChange("entry.458332449")}
              />
              <TextField
                style={{ width: "100%", marginBottom: "20px" }}
                id="outlined-required"
                label="Email"
                value={inputFields["entry.459832011"]}
                name="entry.459832011"
                onFocus={(e) => (e.target.value = "")}
                onChange={handleTextInputChange("entry.459832011")}
              />
              <TextField
                style={{ width: "100%", marginBottom: "20px" }}
                id="outlined-required"
                label="Company"
                value={inputFields["entry.1839313831"]}
                name="entry.1839313831"
                onFocus={(e) => (e.target.value = "")}
                onChange={handleTextInputChange("entry.1839313831")}
              />
              <TextField
                style={{ width: "100%", marginBottom: "20px" }}
                id="outlined-required"
                label="Phone (optional)"
                value={inputFields["entry.1623414976"]}
                name="entry.1623414976"
                onFocus={(e) => (e.target.value = "")}
                onChange={handleTextInputChange("entry.1623414976")}
              />
              <FormControl style={{ width: "55%" }}>
                <InputLabel id="state">State</InputLabel>
                <Select
                  style={{ marginBottom: "30px" }}
                  labelId="state"
                  id="state"
                  label="State"
                  placeholder="Select"
                  value={inputFields["entry.1064217773"]}
                  name="entry.1064217773"
                  onChange={handleTextInputChange("entry.1064217773")}
                >
                  {states.map((state, i) => (
                    <MenuItem value={state}>{state}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <br />
              <FormControl style={{ marginLeft: "5px" }}>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  PEO Master Health
                </FormLabel>
                <RadioGroup
                  style={{ marginBottom: "20px" }}
                  row
                  value={inputFields["entry.1228576801"]}
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="entry.1228576801"
                  onChange={handleOptionChange("entry.1228576801")}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>

              <br />
              <FormControl style={{ width: "55%" }}>
                <InputLabel id="peo">PEO master state</InputLabel>
                <Select
                  style={{ marginBottom: "30px" }}
                  labelId="peo"
                  id="peo"
                  multiple
                  value={statesSelected}
                  label="PEO master states"
                  placeholder="Select"
                  name="entry.1063338339"
                  onChange={handleMultipleChange("entry.1063338339")}
                >
                  {states.map((state, i) => (
                    <MenuItem value={i}>{state}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                name="entry.715344054"
                label="How can we help?"
                onFocus={(e) => (e.target.value = "")}
                onChange={handleTextInputChange("entry.715344054")}
                rows={2}
                maxRows={4}
                style={{ width: "100%" }}
                value={inputFields["entry.715344054"]}
                // rows="10"
                id="text-field"
                type="text"
              />

              <GreenButton
                type="submit"
                text="Send demo request"
                backgroundColor={colors.primaryGreen}
                textColor={colors.primaryBlack}
                onClick={handleSubmit}
              />
            </form>
          </div>
        </div>

        <div className="mobile-responsive demo-logo">
          <DemoLogo />
        </div>
      </div>















      <div
        className="m-auto font-barlow text-center"
        style={{
          borderTop: "1.5px solid black",
          paddingTop: "25px",
          paddingBottom: "25px",
          marginTop: "120px",
          width: "95%",
        }}
      >
        {" "}
        <p style={{ fontSize: "12px", fontWeight: "500" }}>
          Copyright © SlainTech Inc. 2023. All Rights Reserved.&nbsp; |&nbsp;{" "}
          <a href="google.com" className="primary-black-text">
            Privacy Policy
          </a>{" "}
          &nbsp;|&nbsp;{" "}
          <a className="primary-black-text" href="mailto:gv@slaintech.com">
            Contact Us
          </a>
        </p>
      </div>
      <div>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          message="Message sent! Please allow 24-48 hours for a response"
          key={vertical + horizontal}
        />
      </div>
    </div>
  );
};

export default RequestDemo;
