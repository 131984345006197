import React from "react";

export default function DemoImage() {
  return (
    <svg
      className="big-image"
      width="955"
      height="619"
      viewBox="0 0 955 619"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_244_1436)">
        <path
          d="M0 8H929C937.836 8 945 15.1634 945 24V590.716C945 599.552 937.836 606.716 929 606.716H0V8Z"
          fill="white"
        />
      </g>
      <path
        d="M0 71.6472H945V590.716C945 599.552 937.837 606.716 929 606.716H0V71.6472Z"
        fill="#FAFAFA"
      />
      <path
        d="M80.6983 48.9691C79.4311 48.9691 78.3263 48.7685 77.3839 48.3673C76.4416 47.9661 75.7105 47.4044 75.1906 46.6822C74.6869 45.96 74.4351 45.1094 74.4351 44.1305V43.5286C74.4351 43.4484 74.4594 43.3842 74.5082 43.3361C74.5732 43.2719 74.6463 43.2398 74.7275 43.2398H76.8965C76.9777 43.2398 77.0427 43.2719 77.0915 43.3361C77.1565 43.3842 77.189 43.4484 77.189 43.5286V43.986C77.189 44.7243 77.522 45.3421 78.1882 45.8396C78.8543 46.3211 79.7641 46.5618 80.9177 46.5618C81.9087 46.5618 82.6561 46.3532 83.1598 45.9359C83.6634 45.5187 83.9153 44.981 83.9153 44.323C83.9153 43.8737 83.7772 43.4965 83.501 43.1916C83.2248 42.8706 82.8267 42.5818 82.3068 42.325C81.8031 42.0682 81.0395 41.7472 80.016 41.3621C78.8624 40.9448 77.9282 40.5516 77.2133 40.1825C76.4984 39.8134 75.8973 39.3159 75.4099 38.69C74.9387 38.048 74.7031 37.2536 74.7031 36.3068C74.7031 34.8945 75.223 33.7791 76.2629 32.9606C77.3027 32.1421 78.6918 31.7329 80.4303 31.7329C81.6488 31.7329 82.7211 31.9415 83.6472 32.3588C84.5895 32.7761 85.3206 33.3618 85.8406 34.1161C86.3605 34.8544 86.6204 35.713 86.6204 36.6919V37.1012C86.6204 37.1814 86.5879 37.2536 86.5229 37.3178C86.4742 37.366 86.4092 37.39 86.328 37.39H84.1346C84.0534 37.39 83.9803 37.366 83.9153 37.3178C83.8665 37.2536 83.8422 37.1814 83.8422 37.1012V36.8364C83.8422 36.0821 83.5253 35.4482 82.8917 34.9346C82.2743 34.405 81.4132 34.1402 80.3084 34.1402C79.4148 34.1402 78.7162 34.3248 78.2125 34.6939C77.7251 35.063 77.4814 35.5846 77.4814 36.2586C77.4814 36.7401 77.6114 37.1333 77.8713 37.4382C78.1313 37.7431 78.5212 38.024 79.0411 38.2807C79.561 38.5215 80.3653 38.8344 81.4538 39.2196C82.6074 39.6529 83.5253 40.0461 84.2077 40.3992C84.9063 40.7522 85.4994 41.2497 85.9868 41.8917C86.4904 42.5176 86.7423 43.304 86.7423 44.2508C86.7423 45.6952 86.198 46.8427 85.1094 47.6932C84.0371 48.5438 82.5668 48.9691 80.6983 48.9691Z"
        fill="black"
      />
      <path
        d="M89.6136 48.7765C89.5324 48.7765 89.4593 48.7525 89.3943 48.7043C89.3456 48.6401 89.3212 48.5679 89.3212 48.4877V32.2144C89.3212 32.1341 89.3456 32.0699 89.3943 32.0218C89.4593 31.9576 89.5324 31.9255 89.6136 31.9255H91.8557C91.937 31.9255 92.002 31.9576 92.0507 32.0218C92.1157 32.0699 92.1482 32.1341 92.1482 32.2144V48.4877C92.1482 48.5679 92.1157 48.6401 92.0507 48.7043C92.002 48.7525 91.937 48.7765 91.8557 48.7765H89.6136Z"
        fill="black"
      />
      <path
        d="M99.9044 36.2827C100.928 36.2827 101.83 36.4592 102.61 36.8123C103.389 37.1654 103.991 37.6548 104.413 38.2807C104.835 38.8906 105.047 39.5887 105.047 40.3751V48.4877C105.047 48.5679 105.014 48.6401 104.949 48.7043C104.9 48.7525 104.835 48.7765 104.754 48.7765H102.512C102.431 48.7765 102.358 48.7525 102.293 48.7043C102.244 48.6401 102.22 48.5679 102.22 48.4877V47.7173C102.22 47.6692 102.203 47.6451 102.171 47.6451C102.138 47.6291 102.106 47.6371 102.073 47.6692C101.277 48.5358 100.124 48.9691 98.6127 48.9691C97.3942 48.9691 96.3869 48.6642 95.5908 48.0543C94.8109 47.4445 94.421 46.5377 94.421 45.3341C94.421 44.0823 94.8596 43.1114 95.737 42.4213C96.6306 41.7151 97.8816 41.3621 99.4901 41.3621H102.098C102.179 41.3621 102.22 41.322 102.22 41.2417V40.664C102.22 40.0381 102.033 39.5566 101.659 39.2196C101.285 38.8665 100.725 38.69 99.9775 38.69C99.3601 38.69 98.8564 38.8023 98.4665 39.027C98.0928 39.2356 97.8572 39.5325 97.7597 39.9177C97.711 40.1103 97.5973 40.1905 97.4186 40.1584L95.0546 39.8696C94.9734 39.8535 94.9002 39.8294 94.8353 39.7973C94.7865 39.7492 94.7703 39.693 94.7865 39.6288C94.9165 38.6499 95.4445 37.8474 96.3706 37.2215C97.313 36.5956 98.4909 36.2827 99.9044 36.2827ZM99.3195 46.7544C100.116 46.7544 100.798 46.5458 101.367 46.1285C101.935 45.7112 102.22 45.1816 102.22 44.5397V43.4083C102.22 43.328 102.179 43.2879 102.098 43.2879H100.051C99.1895 43.2879 98.5071 43.4484 98.0035 43.7694C97.516 44.0903 97.2723 44.5477 97.2723 45.1415C97.2723 45.6551 97.4592 46.0563 97.8329 46.3452C98.2228 46.618 98.7183 46.7544 99.3195 46.7544Z"
        fill="black"
      />
      <path
        d="M108.324 48.7765C108.242 48.7765 108.169 48.7525 108.104 48.7043C108.055 48.6401 108.031 48.5679 108.031 48.4877V36.7401C108.031 36.6598 108.055 36.5956 108.104 36.5475C108.169 36.4833 108.242 36.4512 108.324 36.4512H110.566C110.647 36.4512 110.712 36.4833 110.761 36.5475C110.826 36.5956 110.858 36.6598 110.858 36.7401V48.4877C110.858 48.5679 110.826 48.6401 110.761 48.7043C110.712 48.7525 110.647 48.7765 110.566 48.7765H108.324Z"
        fill="black"
      />
      <path
        d="M120.067 36.2827C121.383 36.2827 122.431 36.6679 123.211 37.4382C124.007 38.2085 124.405 39.2597 124.405 40.5917V48.4877C124.405 48.5679 124.373 48.6401 124.308 48.7043C124.259 48.7525 124.194 48.7765 124.113 48.7765H121.871C121.79 48.7765 121.716 48.7525 121.651 48.7043C121.603 48.6401 121.578 48.5679 121.578 48.4877V41.1695C121.578 40.4313 121.367 39.8375 120.945 39.3881C120.522 38.9227 119.962 38.69 119.263 38.69C118.564 38.69 117.996 38.9147 117.557 39.364C117.135 39.8134 116.924 40.4072 116.924 41.1454V48.4877C116.924 48.5679 116.891 48.6401 116.826 48.7043C116.777 48.7525 116.712 48.7765 116.631 48.7765H114.389C114.308 48.7765 114.235 48.7525 114.17 48.7043C114.121 48.6401 114.097 48.5679 114.097 48.4877V36.7641C114.097 36.6839 114.121 36.6197 114.17 36.5716C114.235 36.5074 114.308 36.4753 114.389 36.4753H116.631C116.712 36.4753 116.777 36.5074 116.826 36.5716C116.891 36.6197 116.924 36.6839 116.924 36.7641V37.5345C116.924 37.5826 116.94 37.6147 116.972 37.6308C117.005 37.6468 117.029 37.6308 117.045 37.5826C117.712 36.716 118.719 36.2827 120.067 36.2827Z"
        fill="black"
      />
      <path
        d="M137.613 31.9255C137.694 31.9255 137.759 31.9576 137.808 32.0218C137.873 32.0699 137.905 32.1341 137.905 32.2144V34.068C137.905 34.1482 137.873 34.2204 137.808 34.2846C137.759 34.3328 137.694 34.3569 137.613 34.3569H133.129C133.047 34.3569 133.007 34.397 133.007 34.4772V48.4877C133.007 48.5679 132.974 48.6401 132.909 48.7043C132.861 48.7525 132.796 48.7765 132.714 48.7765H130.472C130.391 48.7765 130.318 48.7525 130.253 48.7043C130.204 48.6401 130.18 48.5679 130.18 48.4877V34.4772C130.18 34.397 130.139 34.3569 130.058 34.3569H125.744C125.663 34.3569 125.59 34.3328 125.525 34.2846C125.476 34.2204 125.452 34.1482 125.452 34.068V32.2144C125.452 32.1341 125.476 32.0699 125.525 32.0218C125.59 31.9576 125.663 31.9255 125.744 31.9255H137.613Z"
        fill="black"
      />
      <path
        d="M148.823 41.2417C148.888 41.6429 148.921 42.0923 148.921 42.5898L148.897 43.3361C148.897 43.5286 148.799 43.6249 148.604 43.6249H140.976C140.895 43.6249 140.854 43.665 140.854 43.7453C140.903 44.2749 140.976 44.636 141.074 44.8286C141.464 45.9841 142.414 46.5698 143.925 46.5859C145.03 46.5859 145.907 46.1365 146.557 45.2378C146.622 45.1415 146.703 45.0934 146.801 45.0934C146.866 45.0934 146.923 45.1174 146.971 45.1656L148.482 46.3933C148.628 46.5057 148.653 46.634 148.555 46.7785C148.052 47.4846 147.377 48.0303 146.533 48.4154C145.704 48.8006 144.778 48.9932 143.754 48.9932C142.487 48.9932 141.407 48.7123 140.513 48.1506C139.636 47.5889 138.994 46.8026 138.588 45.7915C138.214 44.9249 138.027 43.7212 138.027 42.1806C138.027 41.2497 138.125 40.4954 138.32 39.9177C138.645 38.7943 139.254 37.9116 140.147 37.2697C141.057 36.6117 142.146 36.2827 143.413 36.2827C146.614 36.2827 148.417 37.9357 148.823 41.2417ZM143.413 38.69C142.812 38.69 142.308 38.8424 141.902 39.1474C141.512 39.4362 141.236 39.8455 141.074 40.3751C140.976 40.6479 140.911 41.001 140.879 41.4343C140.846 41.5145 140.879 41.5547 140.976 41.5547H145.923C146.005 41.5547 146.045 41.5145 146.045 41.4343C146.013 41.0331 145.964 40.7282 145.899 40.5195C145.753 39.9418 145.46 39.4924 145.022 39.1714C144.599 38.8505 144.063 38.69 143.413 38.69Z"
        fill="black"
      />
      <path
        d="M156.696 48.9691C155.445 48.9691 154.364 48.6562 153.454 48.0303C152.544 47.3883 151.911 46.5297 151.553 45.4545C151.293 44.7323 151.163 43.7694 151.163 42.5657C151.163 41.4102 151.293 40.4634 151.553 39.7251C151.895 38.6659 152.52 37.8314 153.43 37.2215C154.356 36.5956 155.445 36.2827 156.696 36.2827C157.963 36.2827 159.068 36.5956 160.01 37.2215C160.952 37.8474 161.578 38.6338 161.887 39.5807C161.968 39.8375 162.025 40.0942 162.057 40.351V40.3992C162.057 40.5596 161.968 40.6559 161.789 40.688L159.596 41.001H159.547C159.401 41.001 159.303 40.9207 159.255 40.7603C159.255 40.68 159.246 40.6158 159.23 40.5677C159.198 40.4393 159.165 40.3269 159.133 40.2306C158.986 39.7813 158.694 39.4122 158.255 39.1233C157.817 38.8344 157.297 38.69 156.696 38.69C156.078 38.69 155.55 38.8505 155.111 39.1714C154.689 39.4764 154.405 39.9017 154.259 40.4473C154.096 40.9609 154.015 41.675 154.015 42.5898C154.015 43.4725 154.088 44.1866 154.234 44.7323C154.397 45.294 154.689 45.7433 155.111 46.0804C155.534 46.4013 156.062 46.5618 156.696 46.5618C157.313 46.5618 157.841 46.4094 158.28 46.1044C158.735 45.7835 159.027 45.3582 159.157 44.8286C159.173 44.7965 159.181 44.7564 159.181 44.7082C159.198 44.6922 159.206 44.6681 159.206 44.636C159.255 44.4434 159.368 44.3632 159.547 44.3953L161.74 44.7323C161.822 44.7483 161.887 44.7885 161.935 44.8526C162 44.9008 162.025 44.973 162.008 45.0693C161.96 45.3261 161.919 45.5106 161.887 45.623C161.578 46.6501 160.952 47.4686 160.01 48.0784C159.068 48.6722 157.963 48.9691 156.696 48.9691Z"
        fill="black"
      />
      <path
        d="M170.422 36.2827C171.738 36.2827 172.786 36.6679 173.566 37.4382C174.362 38.2085 174.76 39.2597 174.76 40.5917V48.4877C174.76 48.5679 174.728 48.6401 174.663 48.7043C174.614 48.7525 174.549 48.7765 174.468 48.7765H172.226C172.145 48.7765 172.071 48.7525 172.007 48.7043C171.958 48.6401 171.933 48.5679 171.933 48.4877V41.1695C171.933 40.4313 171.722 39.8375 171.3 39.3881C170.877 38.9227 170.317 38.69 169.618 38.69C168.92 38.69 168.351 38.9147 167.912 39.364C167.49 39.8134 167.279 40.4072 167.279 41.1454V48.4877C167.279 48.5679 167.246 48.6401 167.181 48.7043C167.132 48.7525 167.067 48.7765 166.986 48.7765H164.744C164.663 48.7765 164.59 48.7525 164.525 48.7043C164.476 48.6401 164.452 48.5679 164.452 48.4877V32.2144C164.452 32.1341 164.476 32.0699 164.525 32.0218C164.59 31.9576 164.663 31.9255 164.744 31.9255H166.986C167.067 31.9255 167.132 31.9576 167.181 32.0218C167.246 32.0699 167.279 32.1341 167.279 32.2144V37.5345C167.279 37.5826 167.295 37.6147 167.327 37.6308C167.36 37.6468 167.384 37.6308 167.4 37.5826C168.067 36.716 169.074 36.2827 170.422 36.2827Z"
        fill="black"
      />
      <circle cx="108.955" cy="32.8116" r="2.15753" fill="#5AE0A0" />
      <circle cx="100.326" cy="32.8116" r="2.15753" fill="#5AE0A0" />
      <g filter="url(#filter1_d_244_1436)">
        <rect x="0.5" y="8.5" width="54.0171" height="597.716" fill="#F4F4F4" />
        <g clip-path="url(#clip0_244_1436)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M36 51L36 53L18 53L18 51L36 51ZM36 46L18 46L18 48L36 48L36 46ZM36 41L18 41L18 43L36 43L36 41Z"
            fill="#49454F"
          />
        </g>
        <rect
          x="0.5"
          y="8.5"
          width="54.0171"
          height="597.716"
          stroke="#EEEEEE"
        />
      </g>
      <rect
        x="6.47266"
        y="80.2773"
        width="42.0719"
        height="42.0719"
        rx="8"
        fill="#5AE0A0"
      />
      <circle cx="26.9692" cy="94.3013" r="6.4726" fill="white" />
      <circle cx="26.9692" cy="163.342" r="6.4726" fill="#D9D9D9" />
      <circle cx="26.9692" cy="224.832" r="6.4726" fill="#D9D9D9" />
      <circle cx="26.9692" cy="286.322" r="6.4726" fill="#D9D9D9" />
      <rect
        x="12.9453"
        y="107.247"
        width="29.1267"
        height="7.55137"
        rx="3.77568"
        fill="white"
      />
      <rect
        x="12.9453"
        y="176.288"
        width="29.1267"
        height="7.55137"
        rx="3.77568"
        fill="#D9D9D9"
      />
      <rect
        x="105"
        y="296.031"
        width="18.339"
        height="7.55137"
        rx="3.77568"
        fill="#D9D9D9"
      />
      <rect
        x="140"
        y="296.031"
        width="18.339"
        height="7.55137"
        rx="3.77568"
        fill="#D9D9D9"
      />
      <rect
        x="175"
        y="296.031"
        width="18.339"
        height="7.55137"
        rx="3.77568"
        fill="#D9D9D9"
      />
      <rect
        x="210"
        y="296.031"
        width="18.339"
        height="7.55137"
        rx="3.77568"
        fill="#D9D9D9"
      />
      <rect
        x="241"
        y="296.031"
        width="18.339"
        height="7.55137"
        rx="3.77568"
        fill="#D9D9D9"
      />
      <rect
        x="241"
        y="296.031"
        width="18.339"
        height="7.55137"
        rx="3.77568"
        fill="#D9D9D9"
      />
      <rect
        x="276"
        y="296.031"
        width="18.339"
        height="7.55137"
        rx="3.77568"
        fill="#D9D9D9"
      />
      <rect
        x="311"
        y="296.031"
        width="18.339"
        height="7.55137"
        rx="3.77568"
        fill="#D9D9D9"
      />
      <rect
        x="346"
        y="296.031"
        width="18.339"
        height="7.55137"
        rx="3.77568"
        fill="#D9D9D9"
      />
      <rect
        x="381"
        y="296.031"
        width="18.339"
        height="7.55137"
        rx="3.77568"
        fill="#D9D9D9"
      />
      <rect
        x="416"
        y="296.031"
        width="18.339"
        height="7.55137"
        rx="3.77568"
        fill="#D9D9D9"
      />
      <rect
        x="451"
        y="296.031"
        width="18.339"
        height="7.55137"
        rx="3.77568"
        fill="#D9D9D9"
      />
      <rect
        x="12.9453"
        y="237.777"
        width="18.339"
        height="7.55137"
        rx="3.77568"
        fill="#D9D9D9"
      />
      <rect
        x="33.4419"
        y="237.777"
        width="8.63014"
        height="7.55137"
        rx="3.77568"
        fill="#D9D9D9"
      />
      <rect
        x="26.9692"
        y="299.267"
        width="15.1027"
        height="7.55137"
        rx="3.77568"
        fill="#D9D9D9"
      />
      <rect
        x="12.9453"
        y="299.267"
        width="10.7877"
        height="7.55137"
        rx="3.77568"
        fill="#D9D9D9"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M816.368 41.7331L822.549 47.9145L820.942 49.5218L814.761 43.3405C813.606 44.1711 812.215 44.6782 810.694 44.6782C806.821 44.6782 803.682 41.5389 803.682 37.6662C803.682 33.7934 806.821 30.6542 810.694 30.6542C814.566 30.6542 817.706 33.7934 817.706 37.6662C817.706 39.1872 817.199 40.5788 816.368 41.7331ZM810.694 32.8117C808.007 32.8117 805.839 34.98 805.839 37.6662C805.839 40.3523 808.007 42.5206 810.694 42.5206C813.38 42.5206 815.548 40.3523 815.548 37.6662C815.548 34.98 813.38 32.8117 810.694 32.8117Z"
        fill="#1C1B1F"
      />
      <rect
        x="875.959"
        y="34.9692"
        width="42.0719"
        height="9.7089"
        rx="4.85445"
        fill="#D9D9D9"
      />
      <rect
        x="74.4351"
        y="111.562"
        width="144.555"
        height="16.1815"
        rx="8"
        fill="#BCBCBC"
      />
      <rect
        x="74.4351"
        y="138.531"
        width="86.3014"
        height="9.7089"
        rx="4.85445"
        fill="#BCBCBC"
      />
      <rect
        x="169.366"
        y="138.531"
        width="38.8356"
        height="9.7089"
        rx="4.85445"
        fill="#BCBCBC"
      />
      <rect
        x="216.832"
        y="138.531"
        width="101.404"
        height="9.7089"
        rx="4.85445"
        fill="#BCBCBC"
      />
      <rect
        x="326.866"
        y="138.531"
        width="29.1267"
        height="9.7089"
        rx="4.85445"
        fill="#BCBCBC"
      />
      <rect
        x="364.623"
        y="138.531"
        width="56.0959"
        height="9.7089"
        rx="4.85445"
        fill="#BCBCBC"
      />
      <rect
        x="225.462"
        y="111.562"
        width="42.0719"
        height="16.1815"
        rx="8"
        fill="#BCBCBC"
      />
      <rect
        x="845.753"
        y="34.9692"
        width="25.8904"
        height="9.7089"
        rx="4.85445"
        fill="#D9D9D9"
      />
      <line x1="80" y1="287.5" x2="492" y2="287.5" stroke="#938F99" />
      <g filter="url(#filter2_d_244_1436)">
        <rect
          x="79.8286"
          y="339.182"
          width="197.414"
          height="227.62"
          rx="16"
          fill="white"
        />
      </g>
      <rect
        x="132.688"
        y="360.757"
        width="50.7021"
        height="11.8664"
        rx="5.93322"
        fill="#BCBCBC"
      />
      <rect
        x="92.7739"
        y="430.877"
        width="166.13"
        height="4.31507"
        rx="2.15753"
        fill="#BCBCBC"
      />
      <rect
        x="92.7739"
        y="443.822"
        width="166.13"
        height="4.31507"
        rx="2.15753"
        fill="#BCBCBC"
      />
      <rect
        x="92.7739"
        y="456.767"
        width="45.3082"
        height="4.31507"
        rx="2.15753"
        fill="#BCBCBC"
      />
      <rect
        x="146.712"
        y="456.767"
        width="112.192"
        height="4.31507"
        rx="2.15753"
        fill="#BCBCBC"
      />
      <rect
        x="92.7739"
        y="469.712"
        width="166.13"
        height="4.31507"
        rx="2.15753"
        fill="#BCBCBC"
      />
      <rect
        x="92.7739"
        y="482.658"
        width="76.5925"
        height="4.31507"
        rx="2.15753"
        fill="#BCBCBC"
      />
      <rect
        x="177.997"
        y="482.658"
        width="80.9075"
        height="4.31507"
        rx="2.15753"
        fill="#BCBCBC"
      />
      <rect
        x="192.021"
        y="360.757"
        width="18.339"
        height="11.8664"
        rx="5.93322"
        fill="#BCBCBC"
      />
      <rect
        x="218.99"
        y="360.757"
        width="39.9144"
        height="11.8664"
        rx="5.93322"
        fill="#BCBCBC"
      />
      <circle cx="107.877" cy="367.23" r="16.1815" fill="#5AE0A0" />
      <g filter="url(#filter3_d_244_1436)">
        <rect
          x="294.503"
          y="339.182"
          width="197.414"
          height="227.62"
          rx="16"
          fill="white"
        />
      </g>
      <rect
        x="347.363"
        y="360.757"
        width="50.7021"
        height="11.8664"
        rx="5.93322"
        fill="#BCBCBC"
      />
      <rect
        x="307.449"
        y="430.877"
        width="166.13"
        height="4.31507"
        rx="2.15753"
        fill="#BCBCBC"
      />
      <rect
        x="307.449"
        y="443.822"
        width="166.13"
        height="4.31507"
        rx="2.15753"
        fill="#BCBCBC"
      />
      <rect
        width="45.3082"
        height="4.31507"
        rx="2.15753"
        transform="matrix(-1 0 0 1 473.579 456.767)"
        fill="#BCBCBC"
      />
      <rect
        width="112.192"
        height="4.31507"
        rx="2.15753"
        transform="matrix(-1 0 0 1 419.641 456.767)"
        fill="#BCBCBC"
      />
      <rect
        x="307.449"
        y="469.712"
        width="166.13"
        height="4.31507"
        rx="2.15753"
        fill="#BCBCBC"
      />
      <rect
        width="76.5925"
        height="4.31507"
        rx="2.15753"
        transform="matrix(-1 0 0 1 473.579 482.658)"
        fill="#BCBCBC"
      />
      <rect
        width="80.9075"
        height="4.31507"
        rx="2.15753"
        transform="matrix(-1 0 0 1 388.356 482.658)"
        fill="#BCBCBC"
      />
      <rect
        width="18.339"
        height="11.8664"
        rx="5.93322"
        transform="matrix(-1 0 0 1 473.579 360.757)"
        fill="#BCBCBC"
      />
      <rect
        width="39.9144"
        height="11.8664"
        rx="5.93322"
        transform="matrix(-1 0 0 1 446.609 360.757)"
        fill="#BCBCBC"
      />
      <circle cx="322.551" cy="367.23" r="16.1815" fill="#5AE0A0" />
      <g filter="url(#filter4_d_244_1436)">
        <rect
          x="509.178"
          y="339.182"
          width="197.414"
          height="227.62"
          rx="16"
          fill="white"
        />
      </g>
      <rect
        width="50.7021"
        height="11.8664"
        rx="5.93322"
        transform="matrix(-1 0 0 1 639.709 360.757)"
        fill="#BCBCBC"
      />
      <rect
        x="522.123"
        y="430.877"
        width="166.13"
        height="4.31507"
        rx="2.15753"
        fill="#BCBCBC"
      />
      <rect
        x="522.123"
        y="443.822"
        width="166.13"
        height="4.31507"
        rx="2.15753"
        fill="#BCBCBC"
      />
      <rect
        x="522.123"
        y="456.767"
        width="45.3082"
        height="4.31507"
        rx="2.15753"
        fill="#BCBCBC"
      />
      <rect x="607" y="192" width="16" height="4" rx="2" fill="#BCBCBC" />
      <rect
        x="576.062"
        y="456.767"
        width="112.192"
        height="4.31507"
        rx="2.15753"
        fill="#BCBCBC"
      />
      <rect x="627" y="192" width="28" height="4" rx="2" fill="#BCBCBC" />
      <rect x="607" y="212" width="40" height="4" rx="2" fill="#BCBCBC" />
      <rect x="607" y="232" width="27" height="4" rx="2" fill="#BCBCBC" />
      <rect x="607" y="252" width="20" height="4" rx="2" fill="#BCBCBC" />
      <rect x="631" y="252" width="20" height="4" rx="2" fill="#BCBCBC" />
      <rect x="638" y="232" width="27" height="4" rx="2" fill="#BCBCBC" />
      <rect
        x="522.123"
        y="469.712"
        width="166.13"
        height="4.31507"
        rx="2.15753"
        fill="#BCBCBC"
      />
      <rect
        x="522.123"
        y="482.657"
        width="76.5925"
        height="4.31507"
        rx="2.15753"
        fill="#BCBCBC"
      />
      <rect
        x="607.346"
        y="482.657"
        width="80.9075"
        height="4.31507"
        rx="2.15753"
        fill="#BCBCBC"
      />
      <rect
        width="18.339"
        height="11.8664"
        rx="5.93322"
        transform="matrix(-1 0 0 1 580.376 360.757)"
        fill="#BCBCBC"
      />
      <rect
        x="648.339"
        y="360.757"
        width="39.9144"
        height="11.8664"
        rx="5.93322"
        fill="#BCBCBC"
      />
      <circle cx="537.226" cy="367.229" r="16.1815" fill="#5AE0A0" />
      <g filter="url(#filter5_d_244_1436)">
        <rect
          x="723.853"
          y="339.182"
          width="197.414"
          height="227.62"
          rx="16"
          fill="white"
        />
      </g>
      <rect
        width="50.7021"
        height="11.8664"
        rx="5.93322"
        transform="matrix(-1 0 0 1 902.928 360.757)"
        fill="#BCBCBC"
      />
      <rect
        x="736.798"
        y="430.877"
        width="166.13"
        height="4.31507"
        rx="2.15753"
        fill="#BCBCBC"
      />
      <rect
        x="736.798"
        y="443.822"
        width="166.13"
        height="4.31507"
        rx="2.15753"
        fill="#BCBCBC"
      />
      <rect
        width="45.3082"
        height="4.31507"
        rx="2.15753"
        transform="matrix(-1 0 0 1 902.928 456.767)"
        fill="#BCBCBC"
      />
      <rect
        width="112.192"
        height="4.31507"
        rx="2.15753"
        transform="matrix(-1 0 0 1 848.99 456.767)"
        fill="#BCBCBC"
      />
      <rect
        x="736.798"
        y="469.712"
        width="166.13"
        height="4.31507"
        rx="2.15753"
        fill="#BCBCBC"
      />
      <rect
        x="736.798"
        y="482.658"
        width="76.5925"
        height="4.31507"
        rx="2.15753"
        fill="#BCBCBC"
      />
      <rect
        x="822.021"
        y="482.658"
        width="80.9075"
        height="4.31507"
        rx="2.15753"
        fill="#BCBCBC"
      />
      <rect
        width="18.339"
        height="11.8664"
        rx="5.93322"
        transform="matrix(-1 0 0 1 843.596 360.757)"
        fill="#BCBCBC"
      />
      <rect
        width="39.9144"
        height="11.8664"
        rx="5.93322"
        transform="matrix(-1 0 0 1 816.627 360.757)"
        fill="#BCBCBC"
      />
      <circle cx="751.901" cy="367.23" r="16.1815" fill="#5AE0A0" />
      <path
        d="M105.5 185C105.5 183.067 107.067 181.5 109 181.5H120C121.933 181.5 123.5 183.067 123.5 185V283.5H105.5V185Z"
        fill="#D1FDE8"
        stroke="#5AE0A0"
      />
      <path
        d="M346.5 185C346.5 183.067 348.067 181.5 350 181.5H361C362.933 181.5 364.5 183.067 364.5 185V283.5H346.5V185Z"
        fill="#EADDFF"
        stroke="#6750A4"
      />
      <path
        d="M175.5 234C175.5 232.067 177.067 230.5 179 230.5H190C191.933 230.5 193.5 232.067 193.5 234V283.5H175.5V234Z"
        fill="#F9DEDC"
        stroke="#B3261E"
      />
      <path
        d="M241.5 212C241.5 210.067 243.067 208.5 245 208.5H256C257.933 208.5 259.5 210.067 259.5 212V283.5H241.5V212Z"
        fill="#EADDFF"
        stroke="#6750A4"
      />
      <path
        d="M412.5 212C412.5 210.067 414.067 208.5 416 208.5H427C428.933 208.5 430.5 210.067 430.5 212V283.5H412.5V212Z"
        fill="#EADDFF"
        stroke="#6750A4"
      />
      <path
        d="M140.5 217C140.5 215.067 142.067 213.5 144 213.5H155C156.933 213.5 158.5 215.067 158.5 217V283.5H140.5V217Z"
        fill="#D5E6FF"
        stroke="#0075E1"
      />
      <path
        d="M210.5 251C210.5 249.067 212.067 247.5 214 247.5H225C226.933 247.5 228.5 249.067 228.5 251V283.5H210.5V251Z"
        fill="#D5E6FF"
        stroke="#0075E1"
      />
      <path
        d="M381.5 251C381.5 249.067 383.067 247.5 385 247.5H396C397.933 247.5 399.5 249.067 399.5 251V283.5H381.5V251Z"
        fill="#D1FDE8"
        stroke="#5AE0A0"
      />
      <path
        d="M447.5 241C447.5 239.067 449.067 237.5 451 237.5H462C463.933 237.5 465.5 239.067 465.5 241V283.5H447.5V241Z"
        fill="#F9DEDC"
        stroke="#B3261E"
      />
      <path
        d="M311.5 180C311.5 178.067 313.067 176.5 315 176.5H326C327.933 176.5 329.5 178.067 329.5 180V283.5H311.5V180Z"
        fill="#D1FDE8"
        stroke="#5AE0A0"
      />
      <path
        d="M276.5 236C276.5 234.067 278.067 232.5 280 232.5H291C292.933 232.5 294.5 234.067 294.5 236V283.5H276.5V236Z"
        fill="#F9DEDC"
        stroke="#B3261E"
      />
      <rect
        opacity="0.5"
        x="199"
        y="177"
        width="103.562"
        height="26.9692"
        rx="8"
        fill="#00160A"
      />
      <rect
        x="208.709"
        y="186.709"
        width="19.4178"
        height="7.55137"
        rx="3.77568"
        fill="white"
      />
      <rect
        x="232.442"
        y="186.709"
        width="11.8664"
        height="7.55137"
        rx="3.77568"
        fill="white"
      />
      <rect
        x="248.623"
        y="186.709"
        width="24.8116"
        height="7.55137"
        rx="3.77568"
        fill="white"
      />
      <path
        d="M287.099 195.699L287.099 186.946L291.119 190.966L292.133 189.945L286.38 184.192L280.626 189.945L281.64 190.959L285.661 186.946L285.661 195.699L287.099 195.699Z"
        fill="#5AE0A0"
      />
      <circle cx="250.781" cy="208.284" r="3.2363" fill="#6750A4" />
      <circle cx="594" cy="194" r="4.5" fill="#D1FDE8" stroke="#5AE0A0" />
      <circle cx="594" cy="214" r="4.5" fill="#D5E6FF" stroke="#0075E1" />
      <circle cx="594" cy="234" r="4.5" fill="#FDE3DF" stroke="#B3261E" />
      <circle cx="594" cy="254" r="4.5" fill="#EADDFF" stroke="#6750A4" />
      <path
        d="M869.434 247.926L792.24 215.98V132.501C838.341 132.77 875.63 170.192 875.63 216.314C875.63 227.154 873.525 237.89 869.434 247.926Z"
        fill="#D1FDE8"
        stroke="#5AE0A0"
      />
      <path
        d="M792.129 220.425L867.646 251.758C863.482 261.662 857.468 270.678 849.927 278.321L792.129 220.425Z"
        fill="#EADDFF"
        stroke="#6750A4"
      />
      <path
        d="M703.501 221.24H787.902L847.582 281.027C814.513 313.775 761.206 313.657 728.282 280.675C712.532 264.897 703.633 243.539 703.501 221.24Z"
        fill="#D5E6FF"
        stroke="#0075E1"
      />
      <path
        d="M787.76 216.76H703.501C703.77 170.345 741.345 132.77 787.76 132.501V216.76Z"
        fill="#FDE3DF"
        stroke="#B3261E"
      />
      <defs>
        <filter
          id="filter0_d_244_1436"
          x="-10"
          y="0"
          width="965"
          height="618.716"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_244_1436"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_244_1436"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_244_1436"
          x="-4"
          y="6"
          width="63.0171"
          height="606.716"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_244_1436"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_244_1436"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_244_1436"
          x="75.8286"
          y="337.182"
          width="205.415"
          height="235.62"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_244_1436"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_244_1436"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_244_1436"
          x="290.503"
          y="337.182"
          width="205.415"
          height="235.62"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_244_1436"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_244_1436"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_d_244_1436"
          x="505.178"
          y="337.182"
          width="205.414"
          height="235.62"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_244_1436"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_244_1436"
            result="shape"
          />
        </filter>
        <filter
          id="filter5_d_244_1436"
          x="719.853"
          y="337.182"
          width="205.414"
          height="235.62"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_244_1436"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_244_1436"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_244_1436">
          <rect
            x="47"
            y="67"
            width="40"
            height="40"
            rx="20"
            transform="rotate(-180 47 67)"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
