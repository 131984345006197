import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import SlainTechLogo from "../../../assets/custom_icons/slain_tech_logo";
import HeroLogo from "../../../assets/custom_icons/hero_logo";
import GreenButton from "../../../common_comp/GreenButton";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { colors } from "../../../utils/colors";
import "@fontsource/roboto/400.css";
import { Link } from "react-scroll";
import { useNavigate } from "react-router-dom";

const demoButtonStyle = {
  fontSize: "18px",
  color: "#381E72",
};

const Hero = () => {
  const navigate = useNavigate();
  return (
    <Grid className="h-vh hero-gradient overflow-hidden">
      <Box
        className="primary-white-text p-l"
        sx={{ width: "100%", height: "15%" }}
      >
        <SlainTechLogo />
      </Box>
      <Grid container className="primary-white-text hero-container">
        <Grid item sx={12} md={12} lg={7}>
          <Grid
            className="hero-text-section"
            sx={{ width: "70%", marginLeft: "14%", textAlign: "left" }}
          >
            <Typography variant="h2" className="font-title m-t-xl font-barlow">
              Empowering PEOs & Broker Partners with a Group Health Portfolio
              Automation Platform
            </Typography>
            <GreenButton
              text="Request a demo"
              icon={ChatBubbleOutlineOutlinedIcon}
              iconStyles={demoButtonStyle}
              backgroundColor={colors.primaryGreen}
              textColor={colors.primaryBlack}
              onClick={() => {
                navigate("/request-demo");
              }}
            />
            <Typography
              className="font-14"
              style={{ marginTop: "32px", letterSpacing: "0.5px" }}
            >
              Questions? <a href="mailto:gv@slaintech.com">Contact our team</a>
            </Typography>
          </Grid>
        </Grid>
        <Grid className="hero-image-container" item sx={12} md={12} lg={5}>
          <box>
            <HeroLogo />
          </box>
        </Grid>
      </Grid>
      <Grid
        sx={{ width: "100%", height: "10%", marginTop: "9%" }}
        className="hero-link"
      >
        <Typography textAlign="center">
          <ArrowDownwardIcon
            style={{
              fontSize: "18px",
              color: colors.primaryGreen,
              marginBottom: "-3px",
            }}
          />{" "}
          <Link
            style={{ cursor: "pointer" }}
            smooth={true}
            hashSpy={true}
            duration={500}
            to="rectangle-cards"
          >
            Learn more{" "}
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Hero;
